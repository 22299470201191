(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

(function ($) {
    function errorBlockHtml(text) {
        return '<span class="help-block error-block-message">' + '<strong>' + text + '</strong>' + '</span>';
    }

    function shake(div) {
        var interval = 10;
        var distance = 6;
        var times = 1;
        $(div).css('position', 'relative');
        for (var iter = 0; iter < times + 1; iter++) {
            $(div).animate({
                left: iter % 2 == 0 ? distance : distance * -1
            }, interval);
        }
        $(div).animate({ left: 0 }, interval);
    }

    function beforeAjaxSend() {
        $(':submit').prop('disabled', true);
        $('.has-error').removeClass('has-error');
        $('.error-block-message').remove();
    }

    $(document).ready(function () {
        $(document).on("click", ".button-done", function () {
            $(".nav-tabs a:last").tab("show");
        });
        $('#registration-form').on('submit', function (event) {
            var _this = this;
            var formData = $(this).serializeArray();
            event.preventDefault();
            $.ajax({
                url: $(this).attr("action"),
                data: formData,
                type: $(this).attr('method'),
                beforeSend: beforeAjaxSend,
                success: function success(response) {
                    $('#register').empty().append(response.html);
                },
                error: function error(xhr) {
                    var errors = xhr.responseJSON;
                    for (var error in errors) {
                        $('#registration-form :input[name=' + error + ']').parent().addClass('has-error');
                        $('#registration-form :input[name=' + error + ']').after(errorBlockHtml(errors[error][0]));
                        shake($('.login-form'));
                    }
                },
                complete: function complete() {
                    $(':submit').prop('disabled', false);
                }
            });
        });

        $('#login-form').on('submit', function (event) {
            event.preventDefault();
            var formData = $(this).serializeArray();
            $.ajax({
                url: $(this).attr("action"),
                data: formData,
                type: $(this).attr('method'),
                beforeSend: beforeAjaxSend,
                success: function success(result) {
                    window.location.href = '/';
                },
                error: function error(xhr) {
                    var errors = xhr.responseJSON;
                    for (var error in errors) {
                        var $input = $('#login-form :input[name=' + error + ']');
                        $input.parent().addClass('has-error');
                        $input.after(errorBlockHtml(errors[error][0]));
                        shake($('.login-form'));
                    }

                    if (errors.success == false) {
                        $input = $('#login-form button.btn-primary--arrow');
                        $input.after(errorBlockHtml(errors['errors']));
                    }
                },
                complete: function complete() {
                    $(':submit').prop('disabled', false);
                }
            });
        });

        $('#login-form .forgot-pass-link').on('click', function (e) {
            e.preventDefault();
            $(".nav-tabs li.active").removeClass('active');

            $("#forget-form").on("submit", function (event) {
                event.preventDefault();

                var username = $('#forget-form').find('[name="email"]').val();
                if (username == '') {
                    var $input = $('#forget-form :input[name=email]');
                    $input.parent().addClass('has-error');
                    shake($('.login-form'));
                    return;
                }

                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });

                $.ajax({
                    url: $("#forget-form").attr('action'),
                    data: {
                        userName: username
                    },
                    type: 'post',
                    success: function success(result) {
                        document.getElementById("send_register").innerHTML = 'Thank you! A password reset request has been sent. If ' + username + ' has an active Evo Profile an email will be sent to you shortly.';
                        alert('Thank you!\nA password reset request has been sent.\nIf ' + username + ' has an active Evo Profile an email will be sent to you shortly.');
                    },
                    error: function error(xhr) {
                        var $input = $('#forget-form :input[name=email]');
                        $input.parent().addClass('has-error');
                        $input.after(errorBlockHtml(xhr.responseJSON.userName));
                        shake($('.login-form'));
                    }
                });
            });
        });
    });
})(jQuery);

},{}]},{},[1]);
